<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$apiService
      .post('/auth/reset-password', {
        token: this.$route.params.token
      })
      .then(() => {
        this.$snotify.success('Ще получите e-mail с новата парола!');
      })
      .catch(err => {
        this.$snotify.error(err.response.data.message);
      })
      .then(() => {
        this.$router.push('/');
      });
  }
};
</script>

<style>
</style>